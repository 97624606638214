import { useContext } from 'react';
import { PosContext } from './PosContext';

export const usePosContext = () => {
  const context = useContext(PosContext);

  if (!context) 
    throw new Error('usePosContext context must be use inside PosProvider');

  return context;
};
