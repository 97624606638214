import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Box } from '@mui/material';

import { useSettingsContext } from '../../components/settings';
import { PosProvider } from '../../pages/pos/context/PosContext';
import Main from './Main';
import Header from './header';
import NavHorizontal from './nav/NavHorizontal';

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isNavHorizontal) {
    return (
      <PosProvider>
        <Header onOpenNav={handleOpen} />
        
        <Main>
          <Outlet />
        </Main>
      </PosProvider>
    );
  }

  if (isNavMini) {
    return (
      <PosProvider>
        <Header />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 }
          }}
        >

          <Main>
            <Outlet />
          </Main>
          
        </Box>      
      </PosProvider>
    );
  }

  return (
    <PosProvider>      
      <Header />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <Main>
          <Outlet />
        </Main>
      </Box>
    </PosProvider>
  );
}