import { RadioGroup } from '@mui/material';
import { useSettingsContext } from '../SettingsContext';
import { StyledCard, StyledWrap, MaskControl, PosLayoutIcon } from '../styles';

const OPTIONS = ['small', 'medium', 'large'];

export default function PictureSizeOptions() {
  const { productImgSize, onChangeProductImgSize } = useSettingsContext();

  return (
    <RadioGroup name="posLayout" value={productImgSize} onChange={onChangeProductImgSize}>
      <StyledWrap sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {OPTIONS.map((size) => (
          <StyledCard key={size} selected={productImgSize === size} sx={{ textTransform: 'capitalize' }}>
            {size}

            <MaskControl value={size} />
          </StyledCard>
        ))}
      </StyledWrap>
    </RadioGroup>
  );
}
