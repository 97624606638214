import { useState } from 'react';
import { Typography, ListItemText, ListItemAvatar, MenuItem, Stack } from '@mui/material';
import { CustomAvatar } from '../../../components/custom-avatar';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { usePosContext } from '../../../pages/pos/context/usePosContext';
import { useLocales } from '../../../locales';
import { IconButtonAnimate } from '../../../components/animate';
import SvgColor from '../../../components/svg-color';
import { useAuthContext } from '../../../auth/useAuthContext';

const ITEM_HEIGHT = 64;

export default function ContactsPopover() {
  const { t } = useLocales();

  const { user } = useAuthContext();
  const [openPopover, setOpenPopover] = useState(null);
  const { currentPOS, pointofsales, onPosChange } = usePosContext();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handkePosChange = (pos) => {
    onPosChange(pos);
    setOpenPopover(null);
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <IconButtonAnimate onClick={user.role <= 2 ? handleOpenPopover : null}>
          <SvgColor src="/assets/icons/files/ic_building.svg" color="#fff" />
        </IconButtonAnimate>

        <Typography variant="h6" onClick={user.role <= 2 ? handleOpenPopover : null}>
          {currentPOS?.name}
        </Typography>
      </Stack>
      
      { pointofsales && 
        <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 320 }}>
          <Typography variant="h6" sx={{ p: 1.5, cursor: 'pointer' }}>
            {t('Point of Sale')} <Typography component="span">({pointofsales.length})</Typography>
          </Typography>

          <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
            {pointofsales.map((pos) => (
              <MenuItem key={pos.id} sx={{ height: ITEM_HEIGHT }} onClick={(ev) => handkePosChange(pos)}>
                <ListItemAvatar>
                  <CustomAvatar name={pos.name} />
                </ListItemAvatar>

                <ListItemText
                  primary={pos.name}
                  secondary={pos.location.address}
                  primaryTypographyProps={{ typography: 'subtitle2', sx: { mb: 0.25 } }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                />
              </MenuItem>
            ))}
          </Scrollbar>
        </MenuPopover>
        }
    </>
  );
}
