import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';

import {
  LoginPage,
  PosPage,
  CustomerSearchPage,
  SaleSearchPage,
  PodiumPage,
  InventoryPage,

  // Common
  PermissionDeniedPage,
  Page500,
  Page403,
  Page404,
  MaintenancePage
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        }
      ],
    },

    // App
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <PosPage />, index: true },
        { element: <CustomerSearchPage />, path: '/customers' },
        { element: <SaleSearchPage />, path: '/sales' },
        { element: <PodiumPage />, path: '/podium' },
        { element: <InventoryPage />, path: '/inventory' }
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
