import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, createContext } from 'react';
import { usePointOfSales } from '../../../hooks/queries/usePointOfSales'
import useLocalStorage from '../../../hooks/useLocalStorage';

export const PosContext = createContext(null);

PosProvider.propTypes = {
  children: PropTypes.node,
};

export function PosProvider({ children }) {
    const { data: pointofsales } = usePointOfSales();
    const [currentPOS, setCurrentPOS] = useLocalStorage('currentPOS', null);

    const initialize = useCallback(() => {
        if(currentPOS === null && pointofsales && pointofsales.length > 0)
            setCurrentPOS(pointofsales[0]);

    }, [currentPOS, setCurrentPOS, pointofsales]);

    const onPosChange = useCallback((pos) => {
        setCurrentPOS(pos);
    }, [setCurrentPOS])

    useEffect(() => {
        initialize();
    }, [initialize]);


    const memoizedValue = useMemo(
        () => ({
            pointofsales,
            currentPOS,
            onPosChange
        }),
        [currentPOS, pointofsales, onPosChange]
    );

    return <PosContext.Provider value={memoizedValue}>{children}</PosContext.Provider>;
}
