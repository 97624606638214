import { RadioGroup } from '@mui/material';
import { useSettingsContext } from '../SettingsContext';
import { StyledCard, StyledWrap, MaskControl, PosLayoutIcon } from '../styles';

const OPTIONS = ['small', 'medium', 'large'];

export default function PosLayoutOptions() {
  const { posLayout, onChangePosLayout } = useSettingsContext();

  return (
    <RadioGroup name="posLayout" value={posLayout} onChange={onChangePosLayout}>
      <StyledWrap sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {OPTIONS.map((layout) => (
          <StyledCard key={layout} selected={posLayout === layout} sx={{ p: 0.75, height: 56 }}>
            <PosLayoutIcon layout={layout} />

            <MaskControl value={layout} />
          </StyledCard>
        ))}
      </StyledWrap>
    </RadioGroup>
  );
}
