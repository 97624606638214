import { Link as RouterLink } from 'react-router-dom';
import { Divider, Alert, Stack, Typography, Link } from '@mui/material';

import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';
import AuthLoginSMSForm from './AuthLoginSMSForm';

export default function Login() {

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 3, position: 'relative' }}>
        <Typography variant="h4">Connectez-vous à Tina</Typography>
      </Stack>

      <Alert severity="info" sx={{ mb: 6 }}>
        Use your <strong>e-mail and password</strong> that we have provided to you
      </Alert>

      <AuthLoginForm />
    </LoginLayout>
  );
}
