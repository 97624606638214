import { useQuery } from 'react-query'
import axios from '../../utils/axios';

export function usePointOfSales() {
    return useQuery(['pointofsales'], () =>
        axios.get(`/pointofsales/`).then(res => res.data),
        {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            staleTime: 10 * 60 * 1000,
            cacheTime: 15 * 60 * 1000
        }
    )
}
