import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar } from '@mui/material';

import { bgBlur } from '../../../utils/cssStyles';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../../../config-global';
import Logo from '../../../components/logo';
import TabNav from '../../../components/tab-nav';
import ContactsPopover from './ContactsPopover';
import AccountPopover from './AccountPopover';
import { useSettingsContext } from '../../../components/settings';
import { useLocales } from '../../../locales';

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { t } = useLocales();

  const { themeLayout } = useSettingsContext();
  const location = useLocation();

  const theme = useTheme();

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'sm');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
      <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ px: 2}}
        >
          {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

          <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
            <TabNav
              title={t('Sale')}
              icon="ic_document"
              link="/"
              selected={location.pathname === '/'}
            />

            <TabNav
              title={t('History')}
              icon="ic_history"
              link="/sales"
              selected={location.pathname.startsWith('/sales')}
            />

            <TabNav
              title={t('Customers')}
              icon="ic_profile"
              link="/customers"
              selected={location.pathname.startsWith('/customers')}
            />

            <TabNav
              title={t('Inventory')}
              icon="ic_calendar"
              link="/inventory"
              selected={location.pathname.startsWith('/inventory')}
            />

            <TabNav
              title={t('Podium')}
              icon="ic_dashboard"
              link="/podium"
              selected={location.pathname.startsWith('/podium')}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
            <ContactsPopover />
            <AccountPopover />
          </Stack>
      </Stack>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 3,
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: '#3C4451',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      
        {renderContent}
      
    </AppBar>
  );
}
