import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { AUTH0_API } from '../config-global';
import { setSession, isValidToken } from './utils';
import axios from '../utils/axios';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

export const AuthContext = createContext(null);

let auth0Client = null;

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      auth0Client = new Auth0Client({
        clientId: AUTH0_API.clientId || '',
        domain: AUTH0_API.domain || '',
        authorizationParams: {
          redirect_uri: window.location.origin,
          responseType: 'openid email token id_token',
          audience: AUTH0_API.audience
        }
      });

      await auth0Client.checkSession();

      const isAuthenticated = await auth0Client.isAuthenticated();
      
      if (isAuthenticated) {
		    const accessToken = await auth0Client.getTokenSilently();
        
        if (accessToken && isValidToken(accessToken)) 
          setSession(accessToken);

        const connectedUser = await axios.get('/team/currentUser/true');
          
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated,
            user: {
              name: connectedUser.data.name,
              role: connectedUser.data.role,
              permissions: connectedUser.data.permissions,
              pictureURL: connectedUser.data.photo ? connectedUser.data.photo.path : null,
              company: { 
                id: connectedUser.data.company.id,
                name: connectedUser.data.company.name,
                nameId: connectedUser.data.company.nameId,
                language: connectedUser.data.company.language,
                mainCurrency: connectedUser.data.company.mainCurrency,
                approveSale: connectedUser.data.company.approveSale
              },
            }
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (smsMode) => {
    const options = {
      authorizationParams: {
        redirect_uri: window.location.origin,
        responseType: 'openid email token id_token'
      },
	  }

    if(smsMode)
      options.authorizationParams.connection = 'sms';
	
    await auth0Client?.loginWithPopup(options);

    const isAuthenticated = await auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      const accessToken = await auth0Client.getTokenSilently();

      if (accessToken && isValidToken(accessToken)) 
        setSession(accessToken);

        const connectedUser = await axios.get('/team/currentUser/true');

        dispatch({
          type: 'LOGIN',
          payload: {
            isAuthenticated,
            user: {
              name: connectedUser.data.name,
              role: connectedUser.data.role,
              permissions: connectedUser.data.permissions,
              pictureURL: connectedUser.data.photo ? connectedUser.data.photo.path : null,
              company: { 
                id: connectedUser.data.company.id,
                name: connectedUser.data.company.name,
                nameId: connectedUser.data.company.nameId,
                language: connectedUser.data.company.language,
                mainCurrency: connectedUser.data.company.mainCurrency,
                approveSale: connectedUser.data.company.approveSale
              }
            }
          },
        });
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    auth0Client?.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
    
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'auth0',
      login,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
