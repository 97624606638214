// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'bold',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: true,
  posLayout: 'small',
  productImgSize: 'large'
};
