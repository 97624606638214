import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loading-screen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const PosPage = Loadable(lazy(() => import('../pages/pos/PosPage')));

export const CustomerSearchPage = Loadable(lazy(() => import('../pages/customers/CustomerSearchPage')));
export const SaleSearchPage = Loadable(lazy(() => import('../pages/sales/SaleSearchPage')));
export const PodiumPage = Loadable(lazy(() => import('../pages/podium/PodiumPage')));
export const InventoryPage = Loadable(lazy(() => import ('../pages/inventory/InventoryPage')));

export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/common/PermissionDeniedPage')));
export const Page500 = Loadable(lazy(() => import('../pages/common/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/common/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/common/MaintenancePage')));