import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Card, Typography, Link } from '@mui/material';
import { useSettingsContext } from '../settings';
import SvgColor from '../svg-color';

TabNav.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  selected: PropTypes.bool
};

export default function TabNav({title, icon, link, selected}) {
  const { themeMode } = useSettingsContext();
  
  const colors = {
    color: '#FFF',
    bgcolor: selected ? '#A08C5D' : 'transparent'
  };
  
  return (
	<Link to={link} component={RouterLink} underline="none">
		<Card
		  sx={{
        py: 1,
        px: 2,
        borderRadius: '6px',
        textAlign: 'center',
        color: colors.color,
        bgcolor: colors.bgcolor,
        boxShadow:0,
        "&:hover": {
            color: '#FFF',
            bgcolor: '#a08c5d9c',
        }
		  }}
		>
			<Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
				<SvgColor src={`/assets/icons/files/${icon}.svg`} />
				<Typography variant="subtitle2">{title}</Typography>
			</Stack>
		</Card>
    </Link>
  );
}
