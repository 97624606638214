import axios from 'axios';
import { HOST_API_KEY } from '../config-global';

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const parseError = (error) => {
  if(typeof error !== 'object')
    return error;

  if(error.title) 
    return error.title;
    
  if(error.errors) 
    return Object.keys(error.errors).map(k => error.errors[k]).join();
  
  return Object.keys(error).map(k => error[k]).join();
}
