import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'
import localStorageAvailable from '../utils/localStorageAvailable';
import { defaultLang } from './config-lang';

let lng = defaultLang.value;
const storageAvailable = localStorageAvailable();

if (storageAvailable) 
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;

i18n
.use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    order: ['querystring', 'localStorage', 'navigator'],
    fallbackLng: defaultLang.value,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;